import React from "react"
import PageRoot from "../components/PageRoot"
import ContainerWithPadding from "../components/ContainerWithPadding"
import { graphql } from "gatsby"
import EventureGrid from "../components/EventureGrid"
import { renderCarouselItem } from "../utils"
import { useTranslation } from "react-i18next"

const AllAuthorPage = ({ data }) => {
  const { t } = useTranslation()
  const authorList = data.eventure.authors
  return (
    <PageRoot title={t("label:authors")} showTitle>
      <div className="all-authors-wrapper">
        <ContainerWithPadding size={"large"}>
          <div className="card-grid-wrapper">
            <EventureGrid
              colSettings={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6, xxl: 4 }}
              dataSource={authorList}
              renderItem={renderCarouselItem}
            />
          </div>
        </ContainerWithPadding>
      </div>
    </PageRoot>
  )
}

export const query = graphql`
  query allAuthors {
    eventure {
      authors(order_by: { lastname: asc }) {
        ...PreviewCardAuthor
      }
    }
  }
`

export default AllAuthorPage
